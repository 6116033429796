
 .datepicker {
  border-bottom: 1px solid #f0f2f5;
  border-radius: 0;
  .item-label {
    position: absolute;
    padding-top: 5px;
    font-size: 14px;
    color: #000;
   }
   .item-input-wrap  input{
    color: transparent;
  }
 }


