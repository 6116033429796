
.icon-sim_container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  .icon-sim {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}
.sim-list .media-list li.media-item:nth-last-child(1) {
  margin-bottom: calc(
    var(--f7-toolbar-height) + var(--f7-safe-area-bottom) + 20px
  );
}

.navbar-previous {
  display: none;
}
