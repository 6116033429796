
.sim-menu {
  padding: 20px 10px 0px 10px;
}

.sim-menu__item {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  span {
    display: flex;
    font-size: var(--f7-actions-button-font-size);
    line-height: var(--f7-actions-button-height);
  }
}
