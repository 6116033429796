@charset "UTF-8";

@font-face {
  font-family: "m2mdata-controller";
  src:url("fonts/m2mdata-controller.eot");
  src:url("fonts/m2mdata-controller.eot?#iefix") format("embedded-opentype"),
    url("fonts/m2mdata-controller.woff") format("woff"),
    url("fonts/m2mdata-controller.ttf") format("truetype"),
    url("fonts/m2mdata-controller.svg#m2mdata-controller") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "m2mdata-controller" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "m2mdata-controller" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-header-apply:before {
  content: "\61";
}
.icon-header-arrow-back:before {
  content: "\62";
}
.icon-header-chat:before {
  content: "\63";
}
.icon-header-delete:before {
  content: "\64";
}
.icon-header-history:before {
  content: "\65";
}
.icon-header-manu:before {
  content: "\66";
}
.icon-header-menu-2:before {
  content: "\67";
}
.icon-header-notification:before {
  content: "\68";
}
.icon-header-notification2:before {
  content: "\69";
}
.icon-menu-credits:before {
  content: "\6a";
}
.icon-menu-home:before {
  content: "\6b";
}
.icon-menu-settings:before {
  content: "\6c";
}
.icon-menu-sign-out:before {
  content: "\6d";
}
.icon-other-acc:before {
  content: "\6e";
}
.icon-other-activation:before {
  content: "\6f";
}
.icon-other-address:before {
  content: "\70";
}
.icon-other-arrow:before {
  content: "\71";
}
.icon-other-asset:before {
  content: "\72";
}
.icon-other-asset-details:before {
  content: "\73";
}
.icon-other-barcode:before {
  content: "\74";
}
.icon-other-battery:before {
  content: "\75";
}
.icon-other-clear:before {
  content: "\76";
}
.icon-other-commands:before {
  content: "\77";
}
.icon-other-coordinates:before {
  content: "\78";
}
.icon-other-custom:before {
  content: "\79";
}
.icon-other-data:before {
  content: "\7a";
}
.icon-other-date:before {
  content: "\41";
}
.icon-other-email:before {
  content: "\42";
}
.icon-other-gprs:before {
  content: "\43";
}
.icon-other-gps:before {
  content: "\44";
}
.icon-other-gsm:before {
  content: "\45";
}
.icon-other-history:before {
  content: "\46";
}
.icon-other-imei:before {
  content: "\47";
}
.icon-other-info:before {
  content: "\48";
}
.icon-other-language:before {
  content: "\49";
}
.icon-other-menu3:before {
  content: "\4a";
}
.icon-other-message:before {
  content: "\4b";
}
.icon-other-messages:before {
  content: "\4c";
}
.icon-other-name:before {
  content: "\4d";
}
.icon-other-notification:before {
  content: "\4e";
}
.icon-other-number:before {
  content: "\4f";
}
.icon-other-password:before {
  content: "\50";
}
.icon-other-phone:before {
  content: "\51";
}
.icon-other-power:before {
  content: "\52";
}
.icon-other-relay:before {
  content: "\53";
}
.icon-other-replaice:before {
  content: "\54";
}
.icon-other-saving:before {
  content: "\55";
}
.icon-other-scan:before {
  content: "\56";
}
.icon-other-search:before {
  content: "\57";
}
.icon-other-send:before {
  content: "\58";
}
.icon-other-sim:before {
  content: "\59";
}
.icon-other-sim-status:before {
  content: "\5a";
}
.icon-other-speed:before {
  content: "\30";
}
.icon-other-unit:before {
  content: "\31";
}
.icon-other-view-password:before {
  content: "\32";
}
.icon-menu:before {
  content: "\33";
}
.icon-activation:before {
  content: "\34";
}
.icon-alerts:before {
  content: "\35";
}
.icon-arrow:before {
  content: "\36";
}
.icon-back:before {
  content: "\37";
}
.icon-barcode:before {
  content: "\38";
}
.icon-commands:before {
  content: "\39";
}
.icon-copy:before {
  content: "\21";
}
.icon-customer:before {
  content: "\22";
}
.icon-data:before {
  content: "\23";
}
.icon-imsi:before {
  content: "\24";
}
.icon-location:before {
  content: "\25";
}
.icon-mail:before {
  content: "\26";
}
.icon-date:before {
  content: "\27";
}
.icon-deactivation:before {
  content: "\28";
}
.icon-manual:before {
  content: "\29";
}
.icon-phone:before {
  content: "\2a";
}
.icon-pin:before {
  content: "\2b";
}
.icon-profile:before {
  content: "\2c";
}
.icon-refresh:before {
  content: "\2d";
}
.icon-remark:before {
  content: "\2e";
}
.icon-reports:before {
  content: "\2f";
}
.icon-sendcommand:before {
  content: "\3a";
}
.icon-users:before {
  content: "\3b";
}
.icon-transfer:before {
  content: "\3c";
}
.icon-time:before {
  content: "\3d";
}
.icon-status:before {
  content: "\3e";
}
.icon-sim:before {
  content: "\3f";
}
.icon-signout:before {
  content: "\40";
}
.icon-settings:before {
  content: "\5b";
}
.icon-price:before {
  content: "\5d";
}
