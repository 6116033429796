.position-absolute{ position: absolute!important; }

.overflow-hidden{ overflow: hidden!important; }

.min-width-36{ min-width: 36px; }
.min-width-80{ min-width: 80px; }

.max-width-560{ max-width: 560px; }

.height-180{ height: 180px }
.margin-horizontal-auto{
  margin-left:auto;
  margin-right:auto;
}
.size-10{ font-size: 10px !important }
.size-12{ font-size: 12px !important }
.size-14{ font-size: 14px !important }
.size-15{ font-size: 15px !important }
.size-16{ font-size: 16px !important }
.size-18{ font-size: 18px !important }
.size-20{ font-size: 20px !important }
.size-22{ font-size: 22px !important }
.size-24{ font-size: 24px !important }

.line-height-14{ line-height: 14px !important }

.text-transform-uppercase{
  text-transform: uppercase!important;
}
.font-style-italic {
  font-style: italic;
}
.font-weight-bold {
  font-weight: bold;
}
.word-break-break-word,
.break-word-item-text .item-text{
  word-break: break-word;
}
.white-space-normal{
  white-space: normal!important;
}


/*.color-green {
  --f7-theme-color: #39B54A;
  --f7-theme-color-shade: #2e913b;
  --f7-theme-color-tint: #5dcc6d;
  --f7-theme-color-rgb: 57,181,74;
}
.color-orange {
  --f7-theme-color: #f7931e;
  --f7-theme-color-shade: #d67708;
  --f7-theme-color-tint: #f9b361;
  --f7-theme-color-rgb: 247,147,30;
}
.color-red {
  --f7-theme-color: #E3072B;
  --f7-theme-color-shade: #b60622;
  --f7-theme-color-tint: #f82c4d;
  --f7-theme-color-rgb: 227,7,43;
}
.color-darkgray {
  --f7-theme-color: #333333;
  --f7-theme-color-rgb: 51,51,51;
  --f7-theme-color-shade: #292929;
  --f7-theme-color-tint: #404040;
}
.color-lightgray {
  --f7-theme-color: #CCCCCC;
  --f7-theme-color-rgb: 204,204,204;
  --f7-theme-color-shade: #a3a3a3;
  --f7-theme-color-tint: #ffffff;
}

.text-color-darkgray{
  --f7-theme-color-text-color: #333333!important;
}
.text-color-lightgray {
  --f7-theme-color-text-color: #CCCCCC;
}
.text-color-green {
  --f7-theme-color-text-color: #39B54A;
}
.text-color-orange {
  --f7-theme-color-text-color: #f7931e;
}
.text-color-red {
  --f7-theme-color-text-color: #E3072B;
}

.bg-color-green {
  --f7-theme-color-bg-color: #39B54A;
}
.bg-color-orange {
  --f7-theme-color-bg-color: #f7931e;
}
.bg-color-red {
  --f7-theme-color-bg-color: #E3072B;
}
.bg-color-lightgray {
  --f7-theme-color-bg-color: #CCCCCC;
}
.bg-color-darkgray {
  --f7-theme-color-bg-color: #333333;
}*/




.bg-custom-color-1{
  background-color: #999999!important;
}
.bg-custom-color-2{
  background-color: #ffcf52!important;;
}
.bg-custom-color-3{
  background-color: #80cc7e!important;;
}
.bg-custom-color-4{
  background-color: #f2a72f!important;;
}
.bg-custom-color-5{
  background-color: #ea373f!important;;
}
.bg-custom-color-6{
  background-color: #3476d6!important;;
}
.bg-custom-color-7{
  background-color: #a48ae2!important;;
}
.bg-custom-color-8{
  background-color: #29ABE2!important;;
}

.bg-custom-color-9{
  background-color: #E06DE2!important;;
}
.bg-custom-color-10{
  background-color: #3CC1C3!important;;
}
.bg-custom-color-11{
  background-color: #796AEC!important;;
}
.bg-custom-color-12{
  background-color: #F75577!important;;
}

.bg-custom-color-13{
  background-color: #E3E3E3!important;;
}
.bg-custom-color-14{
  background-color: #B0C9ED!important;;
}
.bg-custom-color-15{
  background-color: #CDE9CC!important;;
}
.bg-custom-color-16{
  background-color: #F4C789!important;;
}
.bg-custom-color-17{
  background-color: #F4ADB3!important;;
}
.bg-custom-color-18{
  background-color: #CCCCCC!important;;
}
.color-custom {
  --f7-theme-color: #27a6e1;
  --f7-theme-color-rgb: 39,166,225;
  --f7-theme-color-shade: #10aef8;
  --f7-theme-color-tint: #10aef8;
}
.color-gray {
  --f7-theme-color: #8d98b3;
  --f7-theme-color-rgb: 153,153,153;
  --f7-theme-color-shade: #7a7a7a;
  --f7-theme-color-tint: #bfbfbf;
}
.color-secondary {
  --f7-theme-color: #607D8B;
  --f7-theme-color-rgb: 96,125,139;
  --f7-theme-color-shade: #4d646f;
  --f7-theme-color-tint: #7f9aa7;
}
.color-green {
  --f7-theme-color: #01AA7D;
  --f7-theme-color-rgb: 128,204,126;
  --f7-theme-color-shade: #51b94f;
  --f7-theme-color-tint: #bae3b9;
}
.color-blue {
  --f7-theme-color: #00a0d3;
  --f7-theme-color-rgb: 0,160,211;
  --f7-theme-color-shade: #017399;
  --f7-theme-color-tint: #17b4e8;
}
.color-red {
  --f7-theme-color: #ea373f;
  --f7-theme-color-rgb: 234,55,63;
  --f7-theme-color-shade: #d1161e;
  --f7-theme-color-tint: #f1787e;
}
.color-orange {
  --f7-theme-color: #f2a72f;
  --f7-theme-color-rgb: 242,167,47;
  --f7-theme-color-shade: #da8b0e;
  --f7-theme-color-tint: #f6c473;
}
.color-yellow {
  --f7-theme-color: #DFDB27;
  --f7-theme-color-rgb: 255,207,82;
  --f7-theme-color-shade: #ffbc0f;
  --f7-theme-color-tint: #ffe6a6;
}
.color-lightgray {
  --f7-theme-color: #E3E3E3;
  --f7-theme-color-rgb: 227,227,227;
  --f7-theme-color-shade: #b6b6b6;
  --f7-theme-color-tint: #ffffff;
}
.color-lightgreen {
  --f7-theme-color: #CDE9CC;
  --f7-theme-color-rgb: 205,233,204;
  --f7-theme-color-shade: #91cf8f;
  --f7-theme-color-tint: #fff;
}
.color-lightblue {
  --f7-theme-color: #B0C9ED;
  --f7-theme-color-rgb: 176,201,237;
  --f7-theme-color-shade: #6d9bde;
  --f7-theme-color-tint: #fff;
}
.color-lightred {
  --f7-theme-color: #F4ADB3;
  --f7-theme-color-rgb: 244,173,179;
  --f7-theme-color-shade: #ea636f;
  --f7-theme-color-tint: #ffffff;
}
.color-lightorange {
  --f7-theme-color: #F4C789;
  --f7-theme-color-rgb: 244,199,137;
  --f7-theme-color-shade: #eea643;
  --f7-theme-color-tint: #fcf0e0;
}
.bg-color-gray {
  --f7-theme-color-bg-color: #8d98b3;
}
.bg-color-secondary {
  --f7-theme-color-bg-color: #607D8B;
}
.bg-color-green {
  --f7-theme-color-bg-color: #01AA7D;
}
.bg-color-blue {
  --f7-theme-color-bg-color: #00a0d3;
}
.bg-color-red {
  --f7-theme-color-bg-color: #E42330;
}
.bg-color-orange {
  --f7-theme-color-bg-color: #F4A123;
}
.bg-color-yellow {
  --f7-theme-color-bg-color: #DFDB27;
}
.bg-color-brown {
  --f7-theme-color-bg-color: #C69C6D;
}
.bg-color-aqua {
  --f7-theme-color-bg-color: #4FBCB6;
}


.bg-color-lightgray{
  --f7-theme-color-bg-color: #f0f2f5;
}
.bg-color-lightgreen{
  --f7-theme-color-bg-color: #CDE9CC;
}
.bg-color-lightblue{
  --f7-theme-color-bg-color: #B0C9ED;
}
.bg-color-lightred{
  --f7-theme-color-bg-color: #F4ADB3;
}
.bg-color-lightorange{
  --f7-theme-color-bg-color: #F4C789;
}
.bg-color-darkgray {
  --f7-theme-color-bg-color: #333333;
}
.bg-color-customgray {
  --f7-theme-color-bg-color: #8d98b3;
}
.bg-color-custom {
  --f7-theme-color-bg-color: #00a0d3;
}
.bg-color-lilac {
  --f7-theme-color-bg-color: #C23894; //165 143 213
}
.text-color-darkgray{
  --f7-theme-color-text-color: #333333!important;
}
.text-color-lightgray {
  --f7-theme-color-text-color: #888888;
}
.text-color-pelorous {
  --f7-theme-color-text-color: #42B0A7;
}


.text-color-green {
  --f7-theme-color-text-color: #01AA7D; //56 180 145
}
.text-color-orange {
  --f7-theme-color-text-color: #F4A123;
}
.text-color-red {
  --f7-theme-color-text-color: #E42330;
}
.text-color-white {
  --f7-theme-color-text-color: #fff;
}
.text-color-lilac {
  --f7-theme-color-text-color: #C23894; //165 143 213
}
.text-color-blue {
  --f7-theme-color-text-color: #00a0d3;
}
.text-color-yellow {
  --f7-theme-color-text-color: #DFDB27;
}
 
.text-color-customgray {
  --f7-theme-color-text-color: #8d98b3;
}

.text-color-aqua {
  --f7-theme-color-text-color: #4FBCB6;
}